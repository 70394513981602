import _ from 'lodash';
import * as PIXI from 'pixi.js';
import { LoaderResource, Texture } from 'pixi.js';

import variables from '../assets/styles/export.module.scss';
import { config } from '../config';
import { EventTypes } from '../global.d';
import { setBetAmount, setCoinAmount, setCoinValue, setCurrency, setSlotConfig } from '../gql/cache';
import { MAXIMUM_FRACTION_DIGITS, MINIMUM_FRACTION_DIGITS, eventManager } from '../slotMachine/config';
import { Features } from '../slotMachine/d';

export const wait = (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const pixiLoad = (): Promise<Partial<Record<string, PIXI.LoaderResource>>> => {
  return new Promise((resolve, reject) => {
    PIXI.Loader.shared.load((_loader, resources) => {
      const failed = _.filter(resources, (resource) => !!resource?.error);
      if (failed.length) return reject(failed);
      return resolve(resources);
    });
  });
};
export const loadPixiAssets = async (assets: PIXI.IAddOptions[], baseUrl: string): Promise<void> => {
  PIXI.Loader.shared.baseUrl = baseUrl;
  PIXI.Loader.shared.add(assets);
  let tries = config.failureRetries;
  let success = false;

  while (tries > 0) {
    try {
      tries -= 1;
      await pixiLoad();
      success = true;
      break;
    } catch (failed) {
      if (failed instanceof Error) {
        console.error(failed);
      } else {
        console.error(failed);
        const retryResourses = (failed as LoaderResource[]).map(({ name }) => {
          if (PIXI.Loader.shared.resources[name]) {
            const res = PIXI.Loader.shared.resources[name] as LoaderResource;

            // from PIXI.Loader.reset()
            if (res._onLoadBinding) {
              (res._onLoadBinding as { detach: () => boolean }).detach();
            }
            if (res.isLoading) {
              res.abort('loader reset');
            }

            delete PIXI.Loader.shared.resources[name];
          }
          return assets.find((v) => v.name === name)!;
        });
        PIXI.Loader.shared.add(retryResourses);
        await wait(1000);
      }
    }
  }

  if (success === false) {
    throw new Error();
  }
};

export const addTextureCacheFromSpineAtlas = (prefix: string) => {
  const set = new Set();
  for (const resource in PIXI.Loader.shared.resources) {
    const atlas = PIXI.Loader.shared.resources[resource]!.spineAtlas;
    if (atlas) {
      for (const region of atlas.regions) {
        if (region.name.includes('/')) continue; // ignore sequential assets
        if (set.has(region.name)) continue;

        Texture.addToCache(region.texture, `${prefix}${region.name}`);
        set.add(region.name);
      }
    }
  }
};

export const isDevelopment = (): boolean => process.env.NODE_ENV === 'development';

export const isMobilePortrait = (width: number, height: number): boolean => {
  const isPortrait = height >= width;
  const maxWidth = parseInt(variables['breakpointMobilePortraitMax']!, 10);

  return isPortrait && width <= maxWidth;
};

export const isBuyFeatureEnabled = (features: Features[] = []): boolean => {
  const freeSpinFeature = features.find((i) => i.id === 'freeSpins');

  return freeSpinFeature?.enabled || false;
};

export const calcBottomContainerHeight = (width: number, height: number): number => {
  if (isMobilePortrait(width, height)) {
    return height * (parseInt(variables['bottomHeightPercentMobilePortrait']!, 10) / 100);
  }
  return height * (parseInt(variables['bottomHeightPercent']!, 10) / 100);
};

export const normalizeBalance = (balance = 0): number => {
  return balance / 100;
};

export const normalizeCoins = (coins = 0, coinValue = setCoinValue()): number => {
  return (coins * coinValue) / 100;
};

export const showCurrency = (currency: string): boolean => {
  return currency !== 'FUN';
};

export const findSubstituteCoinAmount = (requestedCoinAmount: number, coinAmounts: number[]): number => {
  for (let i = coinAmounts.length - 1; i >= 0; i--) {
    const coinAmount = coinAmounts[i];

    if (coinAmount && coinAmount <= requestedCoinAmount) {
      return coinAmount;
    }
  }

  return coinAmounts[0] ?? 0;
};

export const updateCoinValueAfterBonuses = (): void => {
  // updated coin value from BE after bonus game, because on bonus game we use Coin Value from history
  const coinValue = setSlotConfig().clientSettings.coinValues.find((elem) => elem.code === setCurrency())?.variants[0];
  const coinAmount = findSubstituteCoinAmount(setCoinAmount(), setSlotConfig().clientSettings.coinAmounts.default);
  setCoinValue(coinValue);
  setCoinAmount(coinAmount);
  setBetAmount(coinAmount * setSlotConfig().lineSet.coinAmountMultiplier);
  eventManager.emit(EventTypes.UPDATE_BET);
};

export const formatNumber = (currency = 'FUN', value = 0, showCurrency = false): string => {
  const urlParams = new URLSearchParams(window.location.search);
  const browserLocale = new Intl.Locale(navigator.language || 'us');
  const currentLocale = urlParams.get('lng') || browserLocale.baseName;
  const locale =
    currentLocale.length > 2
      ? currentLocale
      : browserLocale.region
      ? `${currentLocale}-${browserLocale.region}`
      : currentLocale;
  if (currency === 'FUN') {
    return showCurrency
      ? `${currency} ${new Intl.NumberFormat('en', {
          minimumFractionDigits: MINIMUM_FRACTION_DIGITS,
          maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
        }).format(value)}`
      : new Intl.NumberFormat('en', {
          minimumFractionDigits: MINIMUM_FRACTION_DIGITS,
          maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
        }).format(value);
  }
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(value);
};

export const formatNumberNonFraction = (currency = 'FUN', value = 0, showCurrency = false): string => {
  if (currency === 'FUN') {
    return showCurrency
      ? `${currency} ${new Intl.NumberFormat('en', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(value)}`
      : new Intl.NumberFormat('en', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(value);
  }
  return new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

export const nextTick = (callback: () => void): number => window.setTimeout(callback, 0);

export const updateTextScale = (text: PIXI.Text, maxWidth: number, maxHeight: number): void => {
  text.scale.set(1, 1);
  text.updateText(true);
  const ratio = Math.min(1, Math.min(maxWidth / text.width, maxHeight / text.height));
  text.scale.set(ratio, ratio);
};

export const easeInOutQuart = (x: number): number => {
  return x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2;
};

export const easeOutQuart = (x: number): number => {
  return 1 - Math.pow(1 - x, 4);
};

export const easeInSine = (x: number): number => {
  return 1 - Math.cos((x * Math.PI) / 2);
};

export const easeOutQuint = (x: number): number => {
  return 1 - Math.pow(1 - x, 5);
};

export const easeInQuint = (x: number): number => {
  return x * x * x * x * x;
};

export const easeInCubic = (x: number): number => {
  return x * x * x;
};
