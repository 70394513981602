import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, SlotId } from '../../config';
import { EventTypes, PachiDropResult } from '../../global.d';
import {
  setBetAmount,
  setCurrentFreeSpinsTotalWin,
  setGameMode,
  setLastRegularWinAmount,
  setNextResult,
  setWinAmount,
} from '../../gql/cache';
import { isFreeSpinsMode } from '../../utils';
import Tween from '../animations/tween';
import { GameViewLayout, REELBASE_SCALE, eventManager } from '../config';

import { PACHIDROP_COINDROP_DELAY, PACHIDROP_ROUTE, PachiDropPrio, pachiDropPrizeLayout } from './config';
import PachiDropBg from './pachiDropBg';
import PachiDropCoin, { CoinSkin } from './pachiDropCoin';
import { IPachiDropPrizeBase } from './pachiDropPrize/pachiDropPrizeBase';
import { pachiDropPrizeFactory } from './pachiDropPrize/pachiDropPrizeFactory';
import PrizeBgContainer from './pachiDropPrize/prizeBgContainer';

class PachiDrop extends PIXI.Container {
  private background: PachiDropBg;

  private bgFront: PachiDropBg;

  private dropCnt: number;

  private dropCoin: PachiDropCoin;

  private prizeBgContainer: PrizeBgContainer;

  private prizes: IPachiDropPrizeBase[] = [];

  private coinSkin: CoinSkin;

  private roundMax: number;

  private dropInfo: PachiDropResult;

  private vCnt: number;

  constructor() {
    super();

    this.sortableChildren = true;
    this.background = this.initBg();
    this.bgFront = this.initBgFront();
    this.dropCoin = this.initCoin('SC');
    this.prizeBgContainer = this.initPrizeBg();
    this.initPrizes();

    this.background.zIndex = 0;
    this.dropCnt = 0;
    this.coinSkin = 'SC';
    this.roundMax = 0;
    this.dropInfo = {
      reelID: 0,
      route: [],
      position: 0,
      prize: 'lose',
      winMulti: 0,
    };
    this.vCnt = 0;

    this.addChild(this.background);
    this.addChild(this.prizeBgContainer);
    this.addChild(this.bgFront);
    this.addChild(this.dropCoin);

    this.background.zIndex = PachiDropPrio.BG;
    this.dropCoin.zIndex = PachiDropPrio.COIN_FALL;
    this.bgFront.zIndex = PachiDropPrio.BG_FRONT;
    this.prizeBgContainer.zIndex = PachiDropPrio.POT_BG;

    //to baseGamePosition
    this.position.set(
      -GameViewLayout.PachiDrop.ReelBase.left * REELBASE_SCALE,
      -GameViewLayout.PachiDrop.ReelBase.top * REELBASE_SCALE,
    );
    this.scale.set(REELBASE_SCALE);

    eventManager.addListener(EventTypes.PACHIDROP_DROP_START, this.startDrop.bind(this));

    eventManager.addListener(EventTypes.PACHIDROP_PRIZE_ANIM_START, this.startPrizeAnim.bind(this));

    eventManager.addListener(EventTypes.PACHIDROP_NEXT_DROP, this.coinDropEndNext.bind(this));

    eventManager.addListener(EventTypes.PACHIDROP_END, this.initDropInfo.bind(this));

    eventManager.addListener(EventTypes.FREESPINS_PACHIDROP_DROP_END, this.initDropInfo.bind(this));

    eventManager.addListener(EventTypes.PACHIDROP_SHOW_WIN_COUNT, this.showWinMessage.bind(this));

    eventManager.addListener(EventTypes.PACHIDROP_WIN_COUNT_END, this.winCountEnd.bind(this));

    eventManager.addListener(EventTypes.PACHIDROP_SETUP_PRIZES, this.setUpModeChangePrizes.bind(this));
  }

  private initBg(): PachiDropBg {
    const retBg = new PachiDropBg('base');
    retBg.x = GameViewLayout.PachiDrop.BoardBase.left;
    retBg.y = GameViewLayout.PachiDrop.BoardBase.top;
    return retBg;
  }

  private initBgFront(): PachiDropBg {
    const retBg = new PachiDropBg('front');
    retBg.x = GameViewLayout.PachiDrop.BoardBase.left;
    retBg.y = GameViewLayout.PachiDrop.BoardBase.top;
    return retBg;
  }

  private initPrizeBg(): PrizeBgContainer {
    const layout = isFreeSpinsMode(setGameMode()) ? pachiDropPrizeLayout.free : pachiDropPrizeLayout.base;
    const retContainer = new PrizeBgContainer(layout.dispPrizes);
    retContainer.x = GameViewLayout.PachiDrop.Prize.p1.left;
    retContainer.y = GameViewLayout.PachiDrop.Prize.p1.top;

    return retContainer;
  }

  private initPrizes() {
    this.prizes = [];
    const prizesType = isFreeSpinsMode(setGameMode())
      ? pachiDropPrizeLayout.free.dispPrizes
      : pachiDropPrizeLayout.base.dispPrizes;

    prizesType.forEach((type, index) => {
      const pr = pachiDropPrizeFactory(type);
      pr.position.x =
        (GameViewLayout.PachiDrop.Prize.p2.left - GameViewLayout.PachiDrop.Prize.p1.left) * index +
        GameViewLayout.PachiDrop.Prize.p1.left;
      pr.position.y = GameViewLayout.PachiDrop.Prize.p1.top;
      pr.pivot.set(-GameViewLayout.PachiDrop.Prize.p2.width / 2, -GameViewLayout.PachiDrop.Prize.p2.height / 2);
      if (type === 'lose') {
        pr.visible = false;
        pr.zIndex = PachiDropPrio.POT;
      } else if (type === 'v_bs' || type === 'v_fs') {
        pr.zIndex = PachiDropPrio.ALTER;
      } else {
        pr.zIndex = PachiDropPrio.POT;
      }
      this.prizes.push(pr);
      this.addChild(pr);
    });
  }

  private initCoin(skin: CoinSkin): PachiDropCoin {
    const coin = new PachiDropCoin(skin);
    coin.x = GameViewLayout.PachiDrop.Coin.left;
    coin.y = GameViewLayout.PachiDrop.Coin.top;
    return coin;
  }

  private setUpModeChangePrizes() {
    this.removeChild(this.prizeBgContainer);
    this.prizeBgContainer = this.initPrizeBg();
    this.addChild(this.prizeBgContainer);
    this.prizeBgContainer.zIndex = PachiDropPrio.POT_BG;

    this.prizes.forEach((pr) => {
      this.removeChild(pr);
    });
    this.initPrizes();
  }

  private startDrop() {
    const coinReelId: number[] = [];
    let round = 0;
    setNextResult()?.bet.data.features.base.forEach((id, index) => {
      if (id === 'S' || id === 'G') {
        coinReelId.push(index);
        round += 1;
      }
    });
    this.roundMax = round;

    const modePrize = isFreeSpinsMode(setGameMode()) ? pachiDropPrizeLayout.free : pachiDropPrizeLayout.base;

    const pdDropData = setNextResult()?.bet.data.features.pachiDrop[this.dropCnt]!;
    this.dropInfo.position = pdDropData?.position ? pdDropData?.position : 0;
    this.dropInfo.route = PACHIDROP_ROUTE[pdDropData?.value ? pdDropData?.value : 0]!.route;
    this.dropInfo.prize = modePrize.prizes[this.dropInfo.position]!;

    const coinMul = pdDropData?.symbol === 'G' ? 2 : 1;
    this.dropInfo.winMulti = modePrize.winMulti[this.dropInfo.position]! * coinMul;

    this.dropInfo.reelID = coinReelId[this.dropCnt] ?? 0;

    if (this.dropInfo.prize != undefined) {
      this.coinSkin = setNextResult()?.bet.result.spinResult[this.dropInfo.reelID]!.id === SlotId.G ? 'GC' : 'SC';

      const dropDelay = Tween.createDelayAnimation(600);
      dropDelay.addOnStart(() => {
        //prize
        this.setPrizesCoinType(this.coinSkin);
        eventManager.emit(EventTypes.PACHIDROP_DROP_COIN_START, this.dropInfo.reelID);
        if (this.coinSkin === 'GC') {
          AudioApi.play({ type: ISongs.XT002S_Coin_Drop_Gold });
        } else {
          AudioApi.play({ type: ISongs.XT002S_Coin_Drop_Silver });
        }
      });
      dropDelay.addOnComplete(() => {
        this.dropCoin.setSkin(this.coinSkin);
        this.dropCoin.startDropAnimation(this.dropInfo.reelID, this.dropInfo.route, this.dropInfo.prize);
        eventManager.emit(EventTypes.PACHIDROP_DROP_COIN_TRACE, this.dropInfo.reelID);
      });
      dropDelay.start();
    } else {
      this.nextDrop();
    }
  }

  private startPrizeAnim() {
    const index = this.dropInfo.position;
    if (this.dropInfo.prize === 'v_bs' || this.dropInfo.prize === 'v_fs') {
      this.prizes
        .find((prize) => prize.type === 'v_fs' || prize.type === 'v_bs')
        ?.startCoinInAnimation(this.coinSkin === 'GC' ? 'GoldCoin' : 'SilverCoin');
    } else if (this.dropInfo.prize === 'lose') {
      eventManager.emit(EventTypes.PACHIDROP_NEXT_DROP);
      AudioApi.play({ type: ISongs.XT002S_Coin_Lose });
    } else {
      this.prizes[index]!.startCoinInAnimation(this.coinSkin === 'GC' ? 'GoldCoin' : 'SilverCoin');
    }
  }

  public resetPrizes() {
    this.prizes.forEach((prize) => {
      prize.reset();
      switch (prize.type) {
        default:
          break;
        case 'hit3':
        case 'hit6':
          prize.setType('hit3');
          break;
        case 'hit5':
        case 'hit10':
          prize.setType('hit5');
          break;
      }
    });
  }

  public setPrizesCoinType(coinSkin: CoinSkin) {
    this.prizes.forEach((prize) => {
      switch (prize.type) {
        default:
          break;
        case 'hit3':
        case 'hit6':
          prize.setType(coinSkin === 'GC' ? 'hit6' : 'hit3');
          break;
        case 'hit5':
        case 'hit10':
          prize.setType(coinSkin === 'GC' ? 'hit10' : 'hit5');
          break;
      }
    });
  }

  private coinDropEndNext() {
    const anim = Tween.createDelayAnimation(PACHIDROP_COINDROP_DELAY);
    anim.addOnComplete(() => {
      this.nextDrop();
    });
    anim.start();
  }

  private nextDrop() {
    this.dropCnt += 1;
    if (this.dropCnt >= this.roundMax!) {
      eventManager.emit(EventTypes.PACHIDROP_DROP_END);
    } else {
      this.startDrop();
    }
  }

  private showWinMessage() {
    eventManager.emit(EventTypes.PACHIDROP_WIN_START, this.dropInfo.winMulti * setBetAmount(), 'PachiDrop');
  }

  private winCountEnd() {
    const winAmout = this.dropInfo.winMulti * setBetAmount();
    if (isFreeSpinsMode(setGameMode())) {
      setCurrentFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin() + winAmout);
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
    } else {
      setLastRegularWinAmount(setLastRegularWinAmount() + winAmout);

      if (setWinAmount() === 0) {
        setWinAmount(winAmout);
      } else {
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setLastRegularWinAmount());
      }
    }
    this.nextDrop();
  }

  public initDropInfo() {
    this.dropCnt = 0;
    this.vCnt = 0;
    this.resetPrizes();
  }
}

export default PachiDrop;
