import * as PIXI from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { setIsInTransition } from '../../gql/cache';
import { isFreeSpinsMode, updateCoinValueAfterBonuses } from '../../utils';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

class FadeArea extends ViewContainer {
  private sprite: PIXI.Sprite;

  private bindedStartFade = this.startFade.bind(this);

  private bindedStartModeChangeFade = this.startModeChangeFade.bind(this);

  constructor() {
    super();
    this.interactive = false;
    this.sprite = new PIXI.Sprite(PIXI.Texture.WHITE);
    this.sprite.width = 100;
    this.sprite.height = 100;
    this.sprite.tint = 0x000000;
    this.sprite.alpha = 0;
    this.addChild(this.sprite);
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.START_MODE_CHANGE_FADE, this.bindedStartModeChangeFade);
    eventManager.addListener(EventTypes.START_FADE, this.bindedStartFade);
  }

  private resize(width: number, height: number): void {
    this.sprite.width = width;
    this.sprite.height = height;
  }

  private startModeChangeFade(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    fadeOutDuration: number;
    fadeInDuration: number;
  }): void {
    const animationChain: AnimationChain = new AnimationChain();
    const fadeOut = this.getFadeAnimation(1, settings.fadeOutDuration, 0);
    fadeOut.addOnComplete(() => {
      if (!isFreeSpinsMode(settings.mode)) {
        if (settings.mode === GameMode.REGULAR) {
          updateCoinValueAfterBonuses();
        }
        eventManager.emit(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER);
      }
      eventManager.emit(EventTypes.CHANGE_MODE, settings);
    });
    const fadeIn = this.getFadeAnimation(0, settings.fadeInDuration, 1);
    fadeIn.addOnComplete(() => {
      this.interactive = false;
      setIsInTransition(false);
    });
    animationChain.appendAnimation(fadeOut);
    animationChain.appendAnimation(fadeIn);
    animationChain.start();

    this.interactive = true;
    setIsInTransition(true);
  }

  private startFade(fadeOutDuration: number, fadeInDuration: number, tint = 0xffffff): void {
    this.sprite.tint = tint;
    const animationChain: AnimationChain = new AnimationChain();
    const fadeOut = this.getFadeAnimation(1, fadeOutDuration, 0);
    fadeOut.addOnComplete(() => {});
    const fadeIn = this.getFadeAnimation(0, fadeInDuration, 1);
    fadeIn.addOnComplete(() => {
      this.interactive = false;
      setIsInTransition(false);
    });
    animationChain.appendAnimation(fadeOut);
    animationChain.appendAnimation(fadeIn);
    animationChain.start();

    this.interactive = true;
    setIsInTransition(true);
  }

  private getFadeAnimation(alpha: number, duration: number, begin: number): Animation {
    const animation = new Tween({
      object: this.sprite,
      duration,
      propertyBeginValue: begin,
      target: alpha,
      property: TweenProperties.ALPHA,
    });
    return animation;
  }

  public override destroy(options?: boolean | PIXI.IDestroyOptions | undefined): void {
    super.destroy(options);
    eventManager.removeListener(EventTypes.START_FADE, this.bindedStartFade);
    eventManager.removeListener(EventTypes.START_MODE_CHANGE_FADE, this.bindedStartModeChangeFade);
  }
}

export default FadeArea;
