import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { EventTypes, IAuthInput } from '../../global.d';
import {
  setBrokenGameBaseWheel,
  setBrokenGameFreeSpinsWheel,
  setCurrentBonus,
  setIsTimeoutErrorMessage,
  setProgress,
  setReplayBet,
  setSkipIntroScreen,
  setStressful,
} from '../../gql/cache';
import { IConfig } from '../../gql/d';
import { configGql } from '../../gql/query';
import { SlotMachineState, eventManager } from '../../slotMachine/config';
import { parseQuery, queryParams } from '../../utils';
import GameScreen from '../GameScreen';
import LoadScreen from '../LoadScreen';

const App: React.FC = () => {
  const { data } = useQuery<IConfig>(configGql);
  const progress = useReactiveVar(setProgress);
  const { i18n } = useTranslation();

  useEffect(() => {
    const { lng } = parseQuery<IAuthInput>();
    if (lng) {
      i18n.changeLanguage(lng);
    }
  }, [i18n]);

  const handleIdle = useCallback(
    (state: SlotMachineState) => {
      if (state !== SlotMachineState.IDLE) return;
      setIsTimeoutErrorMessage(true);
      setStressful({
        show: true,
        type: 'none',
        message: i18n.t('replayBetMessage'),
      });
      setReplayBet('');
    },
    [i18n],
  );

  useEffect(() => {
    if (queryParams.has('replayBetId')) {
      setSkipIntroScreen(true);
      setReplayBet(queryParams.get('replayBetId') || '');
    }
    eventManager.on(EventTypes.POST_RENDER, () => {
      if (setReplayBet()) {
        eventManager.emit(EventTypes.SET_REPLAY_BET_ID);
        window.setTimeout(() => {
          if (setCurrentBonus().isActive) {
            eventManager.on(EventTypes.NEXT_FREE_SPINS_ROUND, () => {
              eventManager.on(EventTypes.SLOT_MACHINE_STATE_CHANGE, (state: SlotMachineState) => {
                handleIdle(state);
              });
            });
            eventManager.removeAllListeners(EventTypes.PACHIDROP_WHEEL_WIN_COUNT_END);
            eventManager.on(EventTypes.PACHIDROP_WHEEL_WIN_COUNT_END, () => {
              handleIdle(SlotMachineState.IDLE);
            });
          } else if (setBrokenGameBaseWheel() || setBrokenGameFreeSpinsWheel()) {
            eventManager.on(EventTypes.PACHIDROP_WHEEL_WIN_COUNT_END, () => {
              handleIdle(SlotMachineState.IDLE);
            });
          } else {
            eventManager.emit(EventTypes.TOGGLE_SPIN);
            eventManager.on(EventTypes.SLOT_MACHINE_STATE_CHANGE, (state: SlotMachineState) => {
              handleIdle(state);
            });
            eventManager.removeAllListeners(EventTypes.PACHIDROP_DROP_END);
            eventManager.on(EventTypes.PACHIDROP_DROP_END, () => {
              handleIdle(SlotMachineState.IDLE);
            });
          }
        }, 0);
      }
    });
  }, [handleIdle]);

  return (
    <>
      <LoadScreen />
      {data?.isAuthorized && progress?.status >= 100 && <GameScreen />}
    </>
  );
};

export default App;
