import i18n from 'i18next';
import _ from 'lodash';
import * as PIXI from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import {
  ANTICIPATION_START_REELID,
  ANTICIPATION_START_SCCNT,
  ISongs,
  ReelStopSndType,
  SC_REELS,
  SlotId,
  mappedAudioSprites,
} from '../config';
import {
  BetBonusReward,
  BetReward,
  BonusKind,
  EventTypes,
  GameMode,
  ISettledBet,
  UserBonus,
  WheelBonus,
  bonusIds,
  reelSets,
} from '../global.d';
import {
  setBrokenGame,
  setBrokenGameBaseWheel,
  setBrokenGameFreeSpinsWheel,
  setCurrency,
  setCurrentBonus,
  setCurrentFreeSpinsTotalWin,
  setFreeSpinsTotalWin,
  setGameMode,
  setIsBuyFeatureSpin,
  setIsContinueAutoSpinsAfterFeature,
  setIsDuringBigWinLoop,
  setIsDuringWheel,
  setIsDuringWholeSpin,
  setIsFreeSpinsWin,
  setIsRevokeThrowingError,
  setIsSlotBusy,
  setIsWheelWriteQuery,
  setLastRegularWinAmount,
  setNextResult,
  setPrevReelsPosition,
  setReelSetId,
  setSkipIntroScreen,
  setSlotConfig,
  setStressful,
  setUserLastBetResult,
  setWheelBonuses,
  setWinAmount,
} from '../gql/cache';
import client from '../gql/client';
import { ISlotConfig } from '../gql/d';
import { ReelSetType, isStoppedGql } from '../gql/query';
import {
  formatNumber,
  getSpinResult1x3,
  isBuyFeatureEnabled,
  isBuyFeatureMode,
  isFreeSpinsMode,
  normalizeCoins,
  resetWheelBonus,
  showCurrency,
} from '../utils';

import Animation from './animations/animation';
import AnimationGroup from './animations/animationGroup';
import Tween from './animations/tween';
import { AnnouceContainer } from './announce/announceContainer';
import { LOTTBL_SC_ANNOUNCE } from './announce/config';
import { getRandomFromUUID, getResultFromTbl } from './announce/utils';
import Backdrop from './backdrop/backdrop';
import Background from './background/background';
import BackgroundFront from './background/backgroundFront';
import { BgmControl } from './bgmControl/bgmControl';
import BottomContainer from './bottomContainer/bottomContainer';
import AutoplayBtn from './button/autoplayBtn';
import BetBtn from './button/betBtn';
import MenuBtn from './button/menuBtn';
import SoundBtn from './button/soundBtn';
import SpinBtn from './button/spinBtn';
import TurboSpinBtn from './button/turboSpinBtn';
import BuyFeatureBtn from './buyFeature/buyFeatureBtn';
import { BuyFeaturePopup } from './buyFeature/buyFeaturePopup';
import { BuyFeaturePopupConfirm } from './buyFeature/buyFeaturePopupConfirm';
import {
  ANTICIPATION_ENABLE,
  BASEGAME_TO_FREESPIN_REEL_POSITIONS,
  FREEGAME_TO_BASEGAME_REEL_POSITIONS,
  FREE_SPINS_FADE_IN_DURATION,
  FREE_SPINS_FADE_OUT_DURATION,
  FREE_SPINS_TIME_OUT_BANNER,
  JINGLE_TO_WIN_DURATION,
  REELS_AMOUNT,
  SlotMachineState,
  WIN_ANIM_START_DELAY,
  eventManager,
} from './config';
import { Icon } from './d';
import FadeArea from './fadeArea/fadeArea';
import GameView from './gameView/gameView';
import ReelsBackgroundContainer from './reels/background/reelsBackground';
import ReelsContainer from './reels/reelsContainer';
import SafeArea from './safeArea/safeArea';
import { Slot } from './slot/slot';
import SpinAnimation from './spin/spin';
import TintContainer from './tint/tintContainer';
import { SlotsAnimationContainer } from './winAnimations/slotsAnimationContainer';
import WinCountUpMessage from './winAnimations/winCountUpMessage';
import WinLabelContainer from './winAnimations/winLabelContainer';

class SlotMachine {
  private readonly application: PIXI.Application;

  private slotConfig: ISlotConfig;

  public isStopped = false;

  public isReadyForStop = false;

  public nextResult: ISettledBet | null = null;

  public stopCallback: (() => void) | null = null;

  private introSoundDelayAnimation: Animation | undefined;

  private static slotMachine: SlotMachine;

  private isSpinInProgressCallback: () => void;

  private isSlotBusyCallback: () => void;

  public static initSlotMachine = (
    application: PIXI.Application,
    slotConfig: ISlotConfig,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ): void => {
    SlotMachine.slotMachine = new SlotMachine(application, slotConfig, isSpinInProgressCallback, isSlotBusyCallback);
  };

  public static getInstance = (): SlotMachine => SlotMachine.slotMachine;

  public winCountUpMessage: WinCountUpMessage;

  public reelsBackgroundContainer: ReelsBackgroundContainer;

  public reelsContainer: ReelsContainer;

  public tintContainer: TintContainer;

  public slotAnimationContainer: SlotsAnimationContainer;

  public announceContainer: AnnouceContainer;

  public gameView: GameView;

  public winLabelContainer: WinLabelContainer;

  public safeArea: SafeArea;

  public fadeArea: FadeArea;

  public background: Background;

  public backgroundFront: BackgroundFront;

  public bottom: BottomContainer;

  public state: SlotMachineState = SlotMachineState.IDLE;

  public menuBtn: MenuBtn;

  public soundBtn: SoundBtn;

  public turboSpinBtn: TurboSpinBtn;

  public spinBtn: SpinBtn;

  public betBtn: BetBtn;

  public autoplayBtn: AutoplayBtn;

  public buyFeatureBtn?: BuyFeatureBtn;

  public buyFeaturePopup?: BuyFeaturePopup;

  public buyFeaturePopupConfirm?: BuyFeaturePopupConfirm;

  private constructor(
    application: PIXI.Application,
    slotConfig: ISlotConfig,
    isSpinInProgressCallback: () => void,
    isSlotBusyCallback: () => void,
  ) {
    this.application = application;
    this.initListeners();
    this.isSpinInProgressCallback = isSpinInProgressCallback;
    this.isSlotBusyCallback = isSlotBusyCallback;
    this.slotConfig = slotConfig;
    this.reelsBackgroundContainer = new ReelsBackgroundContainer();

    const startPosition = setUserLastBetResult().id
      ? setUserLastBetResult().result.reelPositions
      : slotConfig.settings.startPosition;

    setPrevReelsPosition(startPosition.slice(0, REELS_AMOUNT));
    const reelSet = setUserLastBetResult().id
      ? slotConfig.reels.find((reelSet) => reelSet.id === setUserLastBetResult().reelSetId)!
      : slotConfig.reels.find((reelSet) => reelSet.type === ReelSetType.DEFAULT)!;
    setReelSetId(reelSet.id);
    this.reelsContainer = new ReelsContainer(reelSet.layout, startPosition);
    this.tintContainer = new TintContainer();
    const spinResult = getSpinResult1x3({
      reelPositions: startPosition.slice(0, REELS_AMOUNT),
      reelSet,
      icons: slotConfig.icons,
    });
    this.slotAnimationContainer = new SlotsAnimationContainer();
    eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult);

    this.announceContainer = new AnnouceContainer(spinResult);

    this.background = new Background();

    this.backgroundFront = new BackgroundFront();

    this.winLabelContainer = new WinLabelContainer();
    this.winCountUpMessage = new WinCountUpMessage();
    this.gameView = new GameView({
      announceContainer: this.announceContainer,
      slotStopDisplayContainer: this.slotAnimationContainer,
      reelsBackgroundContainer: this.reelsBackgroundContainer,
      reelsContainer: this.reelsContainer,
      tintContainer: this.tintContainer,
      winLabelContainer: this.winLabelContainer,
      winCountUpMessage: this.winCountUpMessage,
    });
    this.gameView.interactive = true;
    this.gameView.on('mousedown', () => {
      this.skipAnimations();
    });
    this.gameView.on('touchstart', () => {
      this.skipAnimations();
    });

    if (isBuyFeatureEnabled(slotConfig.clientSettings.features)) {
      this.buyFeatureBtn = new BuyFeatureBtn();
      this.buyFeaturePopup = new BuyFeaturePopup();
      this.buyFeaturePopupConfirm = new BuyFeaturePopupConfirm();
      this.gameView.addChild(
        this.buyFeatureBtn,
        new Backdrop(EventTypes.OPEN_BUY_FEATURE_POPUP_BG, EventTypes.CLOSE_BUY_FEATURE_POPUP_BG),
        this.buyFeaturePopup,
        this.buyFeaturePopupConfirm,
      );
      this.buyFeatureBtn.zIndex = 20;
      this.buyFeaturePopup.zIndex = 21;
      this.buyFeaturePopupConfirm.zIndex = 22;
    }
    this.menuBtn = new MenuBtn();
    this.soundBtn = SoundBtn.getInstance();
    this.turboSpinBtn = new TurboSpinBtn();
    this.spinBtn = new SpinBtn();
    this.betBtn = new BetBtn();
    this.autoplayBtn = new AutoplayBtn();
    this.bottom = new BottomContainer();

    this.safeArea = new SafeArea();
    this.safeArea.addChild(this.gameView);

    this.application.stage.addChild(this.background);
    this.application.stage.addChild(this.backgroundFront);
    this.application.stage.addChild(this.safeArea);
    this.application.stage.addChild(this.bottom);
    this.application.stage.addChild(this.menuBtn);
    this.application.stage.addChild(this.soundBtn);
    this.application.stage.addChild(this.turboSpinBtn);
    this.application.stage.addChild(this.spinBtn);
    this.application.stage.addChild(this.betBtn);
    this.application.stage.addChild(this.autoplayBtn);
    this.fadeArea = new FadeArea();
    this.application.stage.addChild(this.fadeArea);
    if (setBrokenGame()) {
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
      this.state = SlotMachineState.WINNING;
      this.onBrokenGame();
      AudioApi.stop({ type: ISongs.XT002S_Openning });
    }
    if (setBrokenGameBaseWheel()) {
      if (setWheelBonuses().bonus.length > 0) {
        eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
        eventManager.emit(EventTypes.PACHIDROP_SET_WHEEL_VIEW);
        this.pachidropShowWheelonBrokenGame();
        setWinAmount(0);
        eventManager.emit(EventTypes.HIDE_WIN_LABEL);
      }
    }
    if (setBrokenGameFreeSpinsWheel()) {
      //changeMode
    }

    if (!setSkipIntroScreen() && !setBrokenGame()) {
      eventManager.emit(EventTypes.START_FADE, 0, 1200);
    }

    eventManager.emit(EventTypes.INITIALIZE_COIN_TO_TRACE, 0);
    eventManager.emit(EventTypes.INITIALIZE_COIN_TO_TRACE, 1);
    eventManager.emit(EventTypes.INITIALIZE_COIN_TO_TRACE, 2);
  }

  private setPachiDropCoinCntFromWheelBonus(): void {
    const [sc, gc] = setWheelBonuses().bonus.reduce(
      ([silverCount, goldCount], bonus) => {
        if (
          bonus.bonusId === bonusIds[BonusKind.WHEEL_SILVER] ||
          bonus.bonusId === bonusIds[BonusKind.FS_WHEEL_SILVER]
        ) {
          return [bonus.rounds, goldCount];
        } else if (
          bonus.bonusId === bonusIds[BonusKind.WHEEL_GOLD] ||
          bonus.bonusId === bonusIds[BonusKind.FS_WHEEL_GOLD]
        ) {
          return [silverCount, bonus.rounds];
        }
        return [silverCount, goldCount];
      },
      [0, 0],
    );
    eventManager.emit(EventTypes.PACHIDROP_SET_COIN_CNT, sc, gc);
  }

  private onBrokenGame(): void {
    const settings = {
      mode: GameMode.FREE_SPINS,
      reelPositions:
        setUserLastBetResult().reelSetId === reelSets[GameMode.REGULAR]!
          ? BASEGAME_TO_FREESPIN_REEL_POSITIONS
          : setUserLastBetResult().result.reelPositions,
      reelSetId: reelSets[GameMode.FREE_SPINS]!,
    };

    setIsFreeSpinsWin(true);
    eventManager.emit(EventTypes.CHANGE_MODE, settings);
    eventManager.emit(
      EventTypes.PACHIDROP_SET_COIN_CNT,
      setUserLastBetResult().data.features.SW,
      setUserLastBetResult().data.features.GW,
    );
    eventManager.emit(EventTypes.HIDE_WIN_LABEL);
  }

  private initListeners(): void {
    eventManager.addListener(EventTypes.RESET_SLOT_MACHINE, this.resetSlotMachine.bind(this));
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.SLOT_MACHINE_STATE_CHANGE, this.onStateChange.bind(this));
    eventManager.addListener(EventTypes.REELS_STOPPED, this.onReelsStopped.bind(this));
    eventManager.addListener(EventTypes.COUNT_UP_END, this.onCountUpEnd.bind(this));
    eventManager.addListener(EventTypes.THROW_ERROR, this.handleError.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.END_FREESPINS, this.endFreeSpins.bind(this));
    eventManager.addListener(EventTypes.HANDLE_CHANGE_RESTRICTION, () => {
      if (setIsDuringBigWinLoop()) {
        AudioApi.play({ type: ISongs.XT002S_BigWin_Loop });
      }
    });

    //pachiDrop
    eventManager.addListener(EventTypes.PACHIDROP_END, this.onPachiDropEnd.bind(this));

    eventManager.addListener(EventTypes.FREESPINS_PACHIDROP_DROP_END, this.onPachiDropEnd.bind(this));
  }

  public throwTimeoutError(): void {
    eventManager.emit(EventTypes.BREAK_SPIN_ANIMATION);
    eventManager.emit(EventTypes.THROW_ERROR);
  }

  private resetSlotMachine(): void {
    eventManager.emit(EventTypes.ROLLBACK_REELS, setPrevReelsPosition());
    AudioApi.stop({ type: ISongs.XT002S_SpinStart_Loop });
    this.setState(SlotMachineState.IDLE);
    this.isSpinInProgressCallback();
    const spinResult = getSpinResult1x3({
      reelPositions: setPrevReelsPosition(),
      reelSet: this.slotConfig.reels.find((reelSet) => reelSet.type === ReelSetType.DEFAULT)!,
      icons: this.slotConfig.icons,
    });
    eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult);
    eventManager.emit(EventTypes.INITIALIZE_COIN_TO_TRACE, 0);
    eventManager.emit(EventTypes.INITIALIZE_COIN_TO_TRACE, 1);
    eventManager.emit(EventTypes.INITIALIZE_COIN_TO_TRACE, 2);
  }

  private onChangeMode(settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
  }) {
    const previousGameMode = setGameMode();
    const currentGameMode = settings.mode;
    if (previousGameMode !== currentGameMode) {
      setGameMode(settings.mode);
      setReelSetId(settings.reelSetId);
      const reelSet = setSlotConfig().reels.find((reels) => reels.id === settings.reelSetId);
      const spinResult = getSpinResult1x3({
        reelPositions: settings.reelPositions.slice(0, REELS_AMOUNT),
        reelSet: reelSet!,
        icons: setSlotConfig().icons,
      });
      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: reelSet!,
        reelPositions: settings.reelPositions,
      });
      eventManager.emit(EventTypes.SHOW_STOP_SLOTS_DISPLAY, spinResult);
    }
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    if (settings.mode === GameMode.REGULAR) {
      setIsFreeSpinsWin(false);
      setCurrentBonus({
        ...setCurrentBonus(),
        isActive: false,
        totalRounds: 0,
      });
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, setNextResult()!.balance.settled);
      if (previousGameMode === GameMode.FREE_SPINS) {
        eventManager.emit(
          EventTypes.UPDATE_WIN_VALUE,
          formatNumber(setCurrency(), normalizeCoins(setCurrentFreeSpinsTotalWin()), showCurrency(setCurrency())),
        );
      }
      this.setState(SlotMachineState.IDLE);
      this.introSoundDelayAnimation?.skip();

      //PCNC-110
      //freespins banner skip not called
      AudioApi.stop({ type: ISongs.XT002S_Total_Win_Banner_1 });
      AudioApi.stop({ type: ISongs.XT002S_Total_Win_Banner_2 });
    } else if (isFreeSpinsMode(settings.mode)) {
      const bonus = setCurrentBonus();
      setIsWheelWriteQuery(false);

      // todo replace with normal error
      if (!bonus) throw new Error('Something went wrong');
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
      const callback = () => {
        if (AudioApi.isRestricted) {
          BgmControl.handleChangeRestriction();
        }
        this.setState(SlotMachineState.IDLE);
      };

      const delay = Tween.createDelayAnimation(setBrokenGame() ? 2000 : 2000);
      delay.addOnComplete(() => {
        this.setState(SlotMachineState.IDLE);
      });
      if (!setBrokenGame()) {
        setCurrentBonus({
          ...bonus,
          currentRound: bonus.roundsPlayed,
          totalRounds: bonus.rounds + bonus.roundsPlayed,
        });
        resetWheelBonus();
      }
      eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);
      eventManager.emit(EventTypes.PACHIDROP_SET_FREESPIN_CNT, setCurrentBonus().currentRound);

      if (!setIsContinueAutoSpinsAfterFeature() && !setBrokenGame()) {
        eventManager.emit(EventTypes.CREATE_MESSAGE_BANNER, {
          title: i18n.t<string>('freeSpinsBanner.title'),
          description: i18n.t<string>('freeSpinsBanner.description', {
            rounds: bonus.rounds,
          }),
          btnText: i18n.t<string>('freeSpinsBanner.startText'),
          callback: callback,
          preventDefaultDestroy: false,
        });
      } else {
        eventManager.emit(EventTypes.SET_FREESPINS_VIEW);
        if (setBrokenGameFreeSpinsWheel()) {
          this.pachidropShowWheelonBrokenGame();
        } else {
          delay.start();
        }
      }
    }
  }

  private pachidropShowWheelonBrokenGame() {
    setIsDuringWholeSpin(true);
    eventManager.emit(EventTypes.BROKEN_GAME_PACHIDROP_SHOW_WHEEL);
    setIsDuringWheel(true);
    this.state = SlotMachineState.WINNING;
    setIsSlotBusy(true);
    BgmControl.stopBgm();
    AudioApi.stop({ type: ISongs.XT002S_Openning });
    this.setPachiDropCoinCntFromWheelBonus();

    const delay = Tween.createDelayAnimation(500);
    delay.addOnComplete(() => {
      eventManager.emit(EventTypes.PACHIDROP_SHOW_WHEEL);
    });
    delay.start();
  }

  private startFreeSpins(): void {
    setIsFreeSpinsWin(true);
    eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
      mode: GameMode.FREE_SPINS,
      reelPositions: BASEGAME_TO_FREESPIN_REEL_POSITIONS,
      reelSetId: reelSets[GameMode.FREE_SPINS]!,
      fadeOutDuration: FREE_SPINS_FADE_OUT_DURATION,
      fadeInDuration: FREE_SPINS_FADE_IN_DURATION,
    });
  }

  private async endFreeSpins(): Promise<void> {
    setWheelBonuses({ bonus: [], currentRound: 0, totalRounds: 0 });
    setFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin());
    setLastRegularWinAmount(setFreeSpinsTotalWin());
    const callback = () => {
      eventManager.emit(EventTypes.START_MODE_CHANGE_FADE, {
        mode: GameMode.REGULAR,
        reelSetId: reelSets[GameMode.REGULAR]!,
        fadeOutDuration: FREE_SPINS_FADE_OUT_DURATION,
        fadeInDuration: FREE_SPINS_FADE_IN_DURATION,
        reelPositions: FREEGAME_TO_BASEGAME_REEL_POSITIONS,
      });
      setPrevReelsPosition(FREEGAME_TO_BASEGAME_REEL_POSITIONS);
    };
    eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MINOR_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MAJOR_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_GRAND_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
    this.skipAnimations();
    const delay = Tween.createDelayAnimation(FREE_SPINS_TIME_OUT_BANNER);
    delay.addOnComplete(() => {
      callback();
    });
    if (!setIsContinueAutoSpinsAfterFeature()) {
      eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, {
        totalWin: `${formatNumber(
          setCurrency(),
          normalizeCoins(setFreeSpinsTotalWin()),
          showCurrency(setCurrency()),
        )} `,
        totalWinAmount: setFreeSpinsTotalWin(),
        preventDefaultDestroy: true,
        callback,
      });
    } else {
      eventManager.emit(EventTypes.CREATE_WIN_MESSAGE_BANNER, {
        totalWin: `${formatNumber(setCurrency(), normalizeCoins(setFreeSpinsTotalWin()), showCurrency(setCurrency()))}`,
        totalWinAmount: setFreeSpinsTotalWin(),
        preventDefaultDestroy: true,
        onInitCallback: () => delay.start(),
      });
    }
    setBrokenGame(false);
    setBrokenGameFreeSpinsWheel(false);
    setBrokenGameBaseWheel(false);
    setIsDuringWheel(false);
    this.isSlotBusyCallback();
  }

  private handleError(): void {
    if (!setIsRevokeThrowingError()) {
      setStressful({
        show: true,
        type: 'network',
        message: i18n.t<string>('error_general'),
      });
    }
  }

  private removeErrorHandler(): void {
    this.reelsContainer.reels[0]!.spinAnimation?.getFakeRolling().removeOnComplete(this.throwTimeoutError);
  }

  public spin(isTurboSpin: boolean | undefined): void {
    this.reelsContainer.forcedStop = false;
    if (this.state === SlotMachineState.SPIN) {
      this.isStopped = true;
      if (this.nextResult) {
        if (!this.isReadyForStop) {
          this.isReadyForStop = true;
          this.removeErrorHandler();
          this.dynamicReelSetChange(this.nextResult.bet.reelSet.id);
          eventManager.emit(
            EventTypes.SETUP_REEL_POSITIONS,
            this.nextResult.bet.result.reelPositions,
            this.getStopSoundSymbolCount(this.nextResult.bet.result.spinResult),
            this.getAnticipationStartReelId(this.nextResult.bet.result.spinResult),
            this.getScatterAnnounceType(this.nextResult.bet.reelSet.id),
          );
        }
        this.stopSpin();
      }
      return;
    }
    if (this.state === SlotMachineState.IDLE) {
      //pcnc-114
      setIsDuringWholeSpin(true);
      setIsWheelWriteQuery(false);
      eventManager.emit(EventTypes.START_SPIN_ANIMATION, isTurboSpin);
      this.skipAnimations();
      eventManager.emit(EventTypes.HIDE_STOP_SLOTS_DISPLAY);
      this.isStopped = false;
      this.isReadyForStop = false;
      this.nextResult = null;
      this.setState(SlotMachineState.SPIN);
      const spinAnimation = this.getSpinAnimation(!isFreeSpinsMode(setGameMode()) && !!isTurboSpin);

      if (isFreeSpinsMode(setGameMode())) {
        setCurrentBonus({
          ...setCurrentBonus(),
          currentRound: setCurrentBonus().currentRound + 1,
        });

        eventManager.emit(EventTypes.PACHIDROP_UPD_FREESPIN_CNT, setCurrentBonus().currentRound);
      }
      spinAnimation.start();

      //PCNC-90
      AudioApi.muteSoundByKey(false, ISongs.XT002S_BGM_BG_Base_Loop);
      AudioApi.muteSoundByKey(false, ISongs.XT002S_BGM_FS_Loop);
    }

    if (this.state === SlotMachineState.WINNING) {
      this.skipAnimations();
    }
  }

  private getSpinAnimation(isTurboSpin: boolean): AnimationGroup {
    const animationGroup = new AnimationGroup();
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const reel = this.reelsContainer.reels[i]!;
      const spinAnimation: SpinAnimation = reel.createSpinAnimation(isTurboSpin);

      if (i === 0) {
        spinAnimation.getFakeRolling().addOnChange(() => {
          if (this.nextResult && !this.isReadyForStop) {
            this.isReadyForStop = true;
            this.removeErrorHandler();
            this.dynamicReelSetChange(this.nextResult.bet.reelSet.id);
            eventManager.emit(
              EventTypes.SETUP_REEL_POSITIONS,
              this.nextResult.bet.result.reelPositions,
              this.getStopSoundSymbolCount(this.nextResult.bet.result.spinResult),
              this.getAnticipationStartReelId(this.nextResult.bet.result.spinResult),
              this.getScatterAnnounceType(this.nextResult.bet.reelSet.id),
            );
          }
        });
        spinAnimation.getFakeRolling().addOnComplete(this.throwTimeoutError);
      }
      this.reelsContainer.reels[i]!.isPlaySoundOnStop = true;

      if (!this.nextResult) {
        if (i === REELS_AMOUNT - 1) {
          spinAnimation.addOnComplete(() => eventManager.emit(EventTypes.REELS_STOPPED, isTurboSpin));
        }
      }
      animationGroup.addAnimation(spinAnimation);
    }

    return animationGroup;
  }

  private getFreeSpinBonus(): UserBonus | undefined {
    const isBonusReward = (reward: BetReward): reward is BetBonusReward => reward.__typename === 'BetBonusReward';

    return this.nextResult?.rewards.filter(isBonusReward).find((reward) => {
      return reward.userBonus?.bonus.type === 'FREE_SPIN';
    })?.userBonus;
  }

  private getSpecialRound(): UserBonus | undefined {
    const isBonusReward = (reward: BetReward): reward is BetBonusReward => reward.__typename === 'BetBonusReward';

    return this.nextResult?.rewards.filter(isBonusReward).find((reward) => {
      return reward.userBonus?.bonus.type === 'SPECIAL_ROUND';
    })?.userBonus;
  }

  private isPachiDrop(): boolean {
    let count = 0;
    this.nextResult!.bet.result.spinResult.forEach((icon, _) => {
      if (icon.id === 'S' || icon.id === 'G') {
        count += 1;
      }
    });
    return count > 0 ? true : false;
  }

  private makeWheelBonusInfo() {
    resetWheelBonus();

    //make wheel info
    setNextResult()!.bet.data.bonuses.forEach((bonus) => {
      const wBonus: WheelBonus = {
        id: bonus.id ?? '',
        bonusId: bonus.bonusId ?? '',
        lineSetId: bonus.lineSetId ?? '',
        coinValue: bonus.coinValue ?? 0,
        coinAmount: bonus.coinAmount ?? 0,
        totalWinAmount: bonus.totalWinAmount ?? 0,
        rounds: bonus.rounds ?? 0,
        betId: bonus.betId ?? '',
      };

      const wheelBonuses = setWheelBonuses();
      wheelBonuses.currentRound = 0;
      wheelBonuses.totalRounds += wBonus.rounds;
      wheelBonuses.bonus.push(wBonus);
    });
  }

  private onCountUpEnd(): void {
    const freeSpinsBonus = this.getFreeSpinBonus();
    const mode = setGameMode();
    const specialRoundBonus = this.getSpecialRound();

    if (freeSpinsBonus) {
      switch (freeSpinsBonus.bonus.id) {
        case bonusIds[BonusKind.BUY_FEATURE]:
          break;
        case bonusIds[BonusKind.FREE_SPINS]:
          setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
          setCurrentFreeSpinsTotalWin(this.nextResult!.bet.result.winCoinAmount);
          setCurrentBonus({
            ...freeSpinsBonus,
            isActive: true,
            currentRound: freeSpinsBonus.roundsPlayed,
            totalRounds: freeSpinsBonus.bonus.rounds + freeSpinsBonus.roundsPlayed,
          });
          this.startFreeSpins();
          this.setState(SlotMachineState.IDLE);
          break;
        default:
          break;
      }

      if (isFreeSpinsMode(mode)) {
        setCurrentFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin() + this.nextResult!.bet.result.winCoinAmount);
        eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
      }
      //}
    } else {
      if (specialRoundBonus) {
        switch (specialRoundBonus.bonus.id) {
          case bonusIds[BonusKind.WHEEL_SILVER]:
          case bonusIds[BonusKind.WHEEL_GOLD]:
          case bonusIds[BonusKind.FS_WHEEL_SILVER]:
          case bonusIds[BonusKind.FS_WHEEL_GOLD]:
            this.makeWheelBonusInfo();
            break;
          default:
            break;
        }
      }
      if (mode === GameMode.REGULAR) {
        if (this.nextResult?.bet.data.features.pachiDrop) {
          setWinAmount(this.getBaseWinAmount());
          setLastRegularWinAmount(this.getBaseWinAmount());
          eventManager.emit(EventTypes.PACHIDROP_TRIGGER);
          eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
          eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
        } else {
          setWinAmount(this.nextResult?.bet.result.winCoinAmount);
          setLastRegularWinAmount(this.nextResult?.bet.result.winCoinAmount);
          eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);
          this.setState(SlotMachineState.IDLE);
        }
      } else if (isFreeSpinsMode(mode)) {
        if (this.nextResult?.bet.data.features.pachiDrop) {
          setCurrentFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin() + this.getBaseWinAmount());
          eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
          eventManager.emit(EventTypes.PACHIDROP_FS_TRIGGER);
          eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
          eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
        } else {
          setCurrentFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin() + this.nextResult!.bet.result.winCoinAmount);
          eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
          if (setCurrentBonus().totalRounds <= setCurrentBonus().currentRound) {
            if (setWheelBonuses().bonus.length > 0) {
              eventManager.emit(EventTypes.PACHIDROP_SHOW_WHEEL);
              return;
            } else {
              setCurrentBonus({ ...setCurrentBonus(), isActive: false });
              eventManager.emit(EventTypes.END_FREESPINS);
              return;
            }
          }
          this.setState(SlotMachineState.IDLE);
        }
      }
    }
  }

  private onPachiDropEnd(): void {
    const mode = setGameMode();
    if (mode === GameMode.REGULAR) {
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, setNextResult()?.balance.settled);
    }
    this.setState(SlotMachineState.IDLE);
  }

  private dynamicReelSetChange(reelSetId: string): void {
    if (setReelSetId() !== reelSetId) {
      eventManager.emit(EventTypes.CHANGE_REEL_SET, {
        reelSet: setSlotConfig().reels.find((reels) => reels.id === reelSetId)!,
        reelPositions: [0, 0, 0],
      });
      setReelSetId(reelSetId);
    }
  }

  private onReelsStopped(isTurboSpin: boolean): void {
    this.onSpinStop(isTurboSpin);
    setIsBuyFeatureSpin(false);
  }

  private getAnticipationStartReelId(spinResult: Icon[]): number {
    if (!ANTICIPATION_ENABLE) return REELS_AMOUNT;

    let minReelId = REELS_AMOUNT;
    // TODO
    const scatterCnt = this.getScatterCount(spinResult);
    if (scatterCnt.some((col, index) => col >= ANTICIPATION_START_SCCNT && index <= ANTICIPATION_START_REELID)) {
      minReelId = ANTICIPATION_START_REELID;
    }
    return minReelId;
  }

  private getStopSoundSymbolCount(spinResult: Icon[]): number[] {
    const reelSnd = [0, 0, 0, 0, 0];
    const scatterCnt = this.getScatterCount(spinResult);
    let setcnt = 0;

    for (const col of SC_REELS) {
      if (scatterCnt[col]! >= 1) {
        reelSnd[col] = ReelStopSndType.Scatter1 + setcnt;
        setcnt += 1;
      } else {
        break;
      }
    }
    return reelSnd;
  }

  private getScatterCount(spinResult: Icon[]): number[] {
    let count = 0;

    return _(spinResult)
      .chunk(REELS_AMOUNT)
      .unzip()
      .map((col) => {
        if (col.some((icon) => icon.id === SlotId.F)) {
          count += 1;
          return count;
        }
        return 0;
      })
      .value();
  }

  private getScatterAnnounceType(_reelSetId: string): number {
    if (isBuyFeatureMode()) return 0;
    if (isFreeSpinsMode(setGameMode())) return 0;

    const goldGoinCnt = this.nextResult!.bet.result.spinResult.filter((icon: Icon) => icon.id === SlotId.G).length;

    const fsCnt = this.nextResult!.bet.result.spinResult.reduce<{
      sc: number;
      reach: number;
    }>(
      (acc, icon, index) => {
        if (icon.id === SlotId.F) {
          acc.sc += 1;
          if (index === 0 || index === 1) {
            acc.reach += 1;
          }
        }
        return acc;
      },
      { sc: 0, reach: 0 },
    );

    let lotType = 0;
    if (goldGoinCnt >= 1) {
      lotType = 3;
    } else if (fsCnt.sc === 3) {
      lotType = 2;
    } else if (fsCnt.reach >= 2) {
      lotType = 1;
    }
    return getResultFromTbl(LOTTBL_SC_ANNOUNCE[lotType]!, getRandomFromUUID(this.nextResult!.bet.id, 1000));
  }

  private skipAnimations(): void {
    eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
    if (this.state === SlotMachineState.IDLE) {
      eventManager.emit(EventTypes.SKIP_WIN_SLOTS_ANIMATION);
    }
  }

  public setResult(result: ISettledBet): void {
    const spinResult = getSpinResult1x3({
      reelPositions: result.bet.result.reelPositions.slice(0, REELS_AMOUNT),
      reelSet: setSlotConfig().reels.find((reelSet) => reelSet.id === result.bet.reelSet.id)!,
      icons: setSlotConfig().icons,
    });
    result.bet.result.spinResult = spinResult;
    setPrevReelsPosition(result.bet.result.reelPositions.slice(0, REELS_AMOUNT));
    this.nextResult = result;
    setNextResult(result);
    if (!isFreeSpinsMode(setGameMode())) {
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult.balance.placed);
    }
  }

  public onSpinStop(_isTurboSpin: boolean | undefined): void {
    this.isSpinInProgressCallback();
    this.setState(SlotMachineState.JINGLE);
  }

  public setStopCallback(fn: () => void): void {
    this.stopCallback = fn;
  }

  public stopSpin(): void {
    eventManager.emit(EventTypes.FORCE_STOP_REELS, false);
    this.setState(SlotMachineState.STOP);
  }

  public getSlotAt(x: number, y: number): Slot | null {
    return this.reelsContainer.reels[x]!.slots[
      (2 * this.reelsContainer.reels[x]!.data.length - this.reelsContainer.reels[x]!.position + y - 1) %
        this.reelsContainer.reels[x]!.data.length
    ]!;
  }

  public getSlotById(id: number): Slot | null {
    return this.getSlotAt(id % REELS_AMOUNT, Math.floor(id / REELS_AMOUNT));
  }

  public getApplication(): PIXI.Application {
    return this.application;
  }

  private resize(_width: number, _height: number): void {}

  private setState(state: SlotMachineState): void {
    this.state = state;
    eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, state);
  }

  private hasWin() {
    let payCnt = 0;
    this.nextResult!.paylines.forEach((line) => {
      if (line.lineId === 0) {
        payCnt += 1;
      }
    });
    return payCnt > 0;
  }

  private onStateChange(state: SlotMachineState): void {
    eventManager.emit(
      EventTypes.DISABLE_BUY_FEATURE_BTN,
      state !== SlotMachineState.IDLE || setIsFreeSpinsWin() || setIsContinueAutoSpinsAfterFeature(),
    );
    if (state === SlotMachineState.IDLE) {
      this.isSlotBusyCallback();
      if (this.stopCallback) {
        this.stopCallback();
        this.stopCallback = null;
      }
      if (isFreeSpinsMode(setGameMode())) {
        if (
          setCurrentBonus().isActive &&
          setCurrentBonus().totalRounds === setCurrentBonus().currentRound &&
          !(setBrokenGame() && setBrokenGameFreeSpinsWheel())
        ) {
          setCurrentBonus({ ...setCurrentBonus(), isActive: false });
          eventManager.emit(EventTypes.END_FREESPINS);
        } else {
          this.skipAnimations();
          setTimeout(
            () => eventManager.emit(EventTypes.NEXT_FREE_SPINS_ROUND),
            setCurrentBonus().currentRound === 0 ? 0 : 500,
          );
        }
      }
      setIsDuringWholeSpin(false);

      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
    }
    if (state === SlotMachineState.JINGLE) {
      if (this.getFreeSpinBonus()?.bonus.id == bonusIds[BonusKind.FREE_SPINS]) {
        const jingleDelay = Tween.createDelayAnimation(
          mappedAudioSprites[ISongs.XT002S_FeatureTrigger]!.duration + JINGLE_TO_WIN_DURATION,
        );
        jingleDelay.addOnStart(() => {
          eventManager.emit(EventTypes.START_FS_WIN_ANIMATION);
        });
        jingleDelay.addOnComplete(() => {
          this.setState(SlotMachineState.WINNING);
        });

        jingleDelay.start();
        AudioApi.stop({ type: ISongs.XT002S_Openning });
        BgmControl.fadeOutAll(1000);
      } else if (this.hasWin()) {
        const jingleDelay = Tween.createDelayAnimation(WIN_ANIM_START_DELAY);
        jingleDelay.addOnStart(() => {});
        jingleDelay.addOnComplete(() => {
          this.setState(SlotMachineState.WINNING);
        });

        jingleDelay.start();
      } else {
        this.setState(SlotMachineState.WINNING);
      }
    }
    if (state === SlotMachineState.WINNING) {
      if (this.hasWin()) {
        eventManager.emit(EventTypes.START_WIN_ANIMATION, this.nextResult!, false);
      } else {
        if (!this.isPachiDrop() && !isFreeSpinsMode(setGameMode())) {
          eventManager.emit(EventTypes.UPDATE_USER_BALANCE, this.nextResult?.balance.settled);
        }

        this.onCountUpEnd();
      }
    }
  }

  private getBaseWinAmount(): number {
    const winLine = this.nextResult!.paylines.filter((line) => {
      return line.lineId === 0;
    });

    return winLine.length != 0 ? winLine[0]!.amount : 0;
  }
}

export default SlotMachine;
